import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { headerData } from "../../data/headerCostum";



const Dashboard = () => {
    const [dataCountLog, setDataCount] = useState(null);

    const getCountOpd = () => {
        fetch(process.env.REACT_APP_API_URL + "api/pendaftar/countopd", {
            method: "get",
            headers: headerData,
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                let count = {};
                let listOPD = [];
                res.data.map((data) => {
                    count[data.opd] = (count[data.opd] || 0) + 1;
                });
                Object.keys(count).forEach(function (key) {
                    // console.log('Key : ' + key + ', Value : ' + count[key])
                    listOPD.push({ 'opd': key, 'value': count[key] });
                })
                // console.log(listOPD);
                setDataCount(listOPD);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (!dataCountLog) {
            getCountOpd();
        }
        const timeout = setTimeout(() => {
            getCountOpd();
          }, 5000);
          
          return () => {
            clearTimeout(timeout);
          };
    }, [getCountOpd])

    return (
        <Box>
            <Grid container direction={"row"}>
                {
                    dataCountLog
                        ? dataCountLog.map((element) => (
                            <Grid item xs={12} sm={12} md={3} paddingX={0.5}>
                                <Box sx={{
                                    padding: "20px",
                                    marginBottom: "5px",
                                    border: 1,
                                    borderRadius: 3,
                                    borderRadius: '10px',
                                    border: '2px solid rgba(255, 211, 124, 0.89)',
                                    background: 'linear-gradient(90deg, #000 0.11%, #920404 71.13%)',
                                    width: "100%"
                                }}>
                                    <Box sx={{
                                        marginBottom: 4,
                                        minHeight: '70px'
                                    }}>
                                        <Typography variant="h5" color={'#FFB200'}>
                                            {element.opd}
                                        </Typography>
                                    </Box>
                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                        <Grid item sx={{
                                            color: '#FFF'
                                        }}>
                                            Jumlah Peserta Terdafatar
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4" color={'#FFB200'}>
                                                {element.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                        ))
                        : ''
                }
            </Grid>
        </Box >
    );
}

export default Dashboard;