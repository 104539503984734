// import * as React from 'react';
import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../assets/image/logo_refleksi.png';
import SweetAlert2 from 'react-sweetalert2';
import { idQR } from '../../data/generateQR';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

const GenerateQR = () => {
    const [swalProps, setSwalProps] = useState({});
    const [textValue, setTextValue] = useState(null);
    const [qrcode, setQrcode] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setQrcode(idQR(data.get('qr')))
    };


    return (
        <Box
            sx={{
                backgroundSize: 'cover',
                alignContent: 'center',
                alignItems: 'center'
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <SweetAlert2 {...swalProps}
                        didClose={() => {
                            setSwalProps({
                            });
                            setTextValue('');
                        }}
                    />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <img src={Logo} alt="logo" width={'300px'} />
                        </Box>
                        <Typography>
                            Generate Code QR
                        </Typography>
                        <TextField
                                margin="normal"
                                value={qrcode}
                                required
                                fullWidth
                                name="qr"
                                label="QR Code"
                                type="text"
                                id="qr"
                                // autoComplete="current-password"
                                size='small'
                            />
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: 500 }}>
                            <TextField
                                margin="normal"
                                value={textValue}
                                onChange={(event) => setTextValue(event.target.value)}
                                required
                                fullWidth
                                name="no_hp"
                                label="No HP"
                                type="text"
                                id="no_hp"
                                // autoComplete="current-password"
                                size='small'
                            />
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 2 }} />
                </Container>
            </ThemeProvider>
        </Box>
    );
}

export default GenerateQR;