// import * as React from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { headerData } from '../../data/headerCostum';
import Logo from '../../assets/image/logo_refleksi.png';
import Background from '../../assets/image/background_refleksi.png';
import { idQR } from '../../data/generateQR';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import SweetAlert2 from 'react-sweetalert2';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();


const Pendaftaran = () => {
    const navigate = useNavigate();
    const [skpd, setSkpd] = useState(null);
    const [opd, setOpd] = useState(null)
    const [swalProps, setSwalProps] = useState({});
    const [data, setData] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = (event) => {
        event.preventDefault();
        setOpenDialog(true);
        const data = new FormData(event.currentTarget);
        setData(data);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const sendData = () => {
        fetch(process.env.REACT_APP_API_URL + "api/pendaftar", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify({
                nama: data.get('nama').toString().toUpperCase(),
                no_hp: data.get('no_hp'),
                opd: opd.nama,
                jabatan: data.get('jabatan'),
                qr: idQR(data.get('no_hp')),
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.nama) {
                    setSwalProps({
                        show: true,
                        title: 'Sukses',
                        icon: 'success',
                        text: 'Data telah ditambahkan'
                    });
                    navigate('/sukses', {
                        state: {
                            user: res
                        }
                    });
                } else {
                    setSwalProps({
                        show: true,
                        title: 'Gagal',
                        icon: 'error',
                        text: res.message
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
        setOpenDialog(false);
    }

    const getDataSKPD = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/skpd", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                setSkpd(res.sort((a, b) => (a.nama) - (b.nama)));
            })
            .catch(err => {
                console.log(err)
            });
    }, [setSkpd])

    useEffect(() => {
        if (!skpd) {
            getDataSKPD();
        }
    }, [skpd, getDataSKPD])

    return (
        <Box
            width={'100vw'}
            height={'100vh'}
            sx={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'absolute',
                alignContent: 'center',
                alignItems: 'center'
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs"
                >
                    <CssBaseline />
                    <SweetAlert2 {...swalProps}
                        didClose={() => {
                            setSwalProps({
                            });
                        }}
                    />
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle align='center' sx={{ justifyItems: 'center' }} id="alert-dialog-title">
                            Konfirmasi
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Apakah anda yakin bahwa data yang dimasukkan telah sesuai ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{
                            justifyContent: 'space-between'
                        }}>
                            <Button variant='contained' onClick={handleCloseDialog}
                                sx={{
                                    background: '#B40000'
                                }}
                            >
                                Kembali</Button>
                            <Button variant='contained' onClick={sendData} autoFocus
                                sx={{
                                    background: '#4985F8'
                                }}
                            >
                                Yakin
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <img src={Logo} alt="Logo" width={'300px'} />
                        </Box>
                        <Box component="form" onSubmit={handleOpenDialog} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="nama"
                                label="Nama"
                                name="nama"
                                // autoComplete="username"
                                autoFocus
                                size='small'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="no_hp"
                                label="No Handphone"
                                type="number"
                                id="no_hp"
                                // autoComplete="current-password"
                                size='small'
                            />
                            <Autocomplete
                                disablePortal
                                id="opd"
                                options={skpd}
                                value={opd}
                                getOptionLabel={(option) => option.nama}
                                onChange={(event, newValue) => {
                                    setOpd(newValue)
                                }}
                                fullWidth
                                renderInput={(params) => <TextField margin='dense' {...params} fullWidth size='small' label="Organisasi Perangkat Daerah" />}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="jabatan"
                                label="Jabatan"
                                type="text"
                                id="jabatan"
                                // autoComplete="current-password"
                                size='small'
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: '#5D0000',
                                    textTransform: 'none',
                                    ":hover": {
                                        backgroundColor: '#B40000'
                                    }
                                }}
                                size='medium'
                            >
                                Daftar
                            </Button>
                        </Box>
                        <Box sx={{ fontSize: 14 }}>
                            Lupa QR Anda ? Silahkan cek
                            <Button
                                onClick={() => navigate('cekqr')}
                            >di SINI</Button>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 2, mb: 2 }} />
                </Container>
            </ThemeProvider>
        </Box>
    );
}

export default Pendaftaran;