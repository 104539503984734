// import * as React from 'react';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { headerData } from '../../data/headerCostum';
import Logo from '../../assets/image/logo_refleksi.png';
import Background from '../../assets/image/background_refleksi.png'
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

const Cekqr = () => {
    const navigate = useNavigate();
    const [swalProps, setSwalProps] = useState({});
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        fetch(process.env.REACT_APP_API_URL + "api/pendaftar/qr/" + data.get('no_hp'), {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if(res.nama){
                    navigate('/sukses', {
                        state: {
                            user: res
                        }
                    });
                }else{
                    setSwalProps({
                        show: true,
                        title: 'Gagal',
                        icon: 'error',
                        text: res.message
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
    };


    return (
        <Box
            width={'100vw'}
            height={'100vh'}
            sx={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'absolute',
                alignContent: 'center',
                alignItems: 'center'
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <SweetAlert2 {...swalProps}
                        didClose={() => {
                            setSwalProps({
                            });
                        }}
                    />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <img src={Logo} alt="logo" width={'300px'} />
                        </Box>
                        <Typography>
                            Cek QR Anda
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="no_hp"
                                label="No Handphone"
                                type="number"
                                id="no_hp"
                                // autoComplete="current-password"
                                size='small'
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mb: 2,
                                    backgroundColor: '#5D0000',
                                    textTransform: 'none',
                                    ":hover": {
                                        backgroundColor: '#B40000'
                                    }
                                }}
                                size='medium'
                            >
                                Cek QR
                            </Button>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 2 }} />
                </Container>
            </ThemeProvider>
        </Box>
    );
}

export default Cekqr;