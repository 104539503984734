import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? 'https://registrasievent.makassarkota.go.id/' : 'http://localhost:8055';
const user = await JSON.parse(localStorage.getItem('user'));

export const socket = io(URL, {
  autoConnect: false,
  path: '/ws',
  auth: {
    token: user ? user.token : ''
  }
});