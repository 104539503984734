import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { headerData } from "../../data/headerCostum";
import { socket } from "../../data/socket";
import { Card, CardContent, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ToMonth from "../../data/convertToMonth";

const Loguser = () => {
  const [dataLog, setDataLog] = useState([]);
  const [countLog, setCountLog] = useState(0);
  const scrollRef = useRef('');
  const [socketStatus, setSocketStatus] = useState('diconnected');

  const scrollExecute = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' });

  const getDataLog = useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "api/pendaftar/logger", {
      method: "get",
      headers: headerData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setDataLog(res.reverse().slice(0, 10).reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setDataLog]);

  const getCountLog = useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "api/pendaftar/countlog", {
      method: "get",
      headers: headerData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setCountLog(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setCountLog]);

  const convertScan = (value) => {
    if (value % 2 === 0) {
      return <Chip color="error" label="Keluar" variant="filled" />;
    } else {
      return <Chip color="success" label="Masuk" variant="filled" />;
    }
  };

  useEffect(() => {
    if (dataLog.length === 0) {
      getDataLog();
      getCountLog();
    }
  }, [dataLog, getDataLog, getCountLog])

  useEffect(() => {
    socket.connect();
    socket.on("connect", () => {
      setSocketStatus('connected');
    })
    socket.on("data_logger", (data) => {
      if (data != null) {
        setDataLog([...dataLog, data]);
        getCountLog();
        scrollExecute();
      }
    });
    return () => { socket.disconnect(); setSocketStatus('disconnected'); }
  }, [setDataLog, dataLog, setSocketStatus]);

  return (
    <Box>
      <Box>
        server: {socketStatus}
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ height: '65vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Tanggal</TableCell>
                <TableCell align="center">Waktu</TableCell>
                <TableCell align="center">Nama</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLog.map((row) => (
                <TableRow
                  key={row.id}
                >
                  <TableCell align="center" sx={{
                    fontWeight: 700
                  }}>{new Date(row.createdAt).getDate() + ' ' + ToMonth(new Date(row.createdAt).getMonth()) + ', ' + new Date(row.createdAt).getFullYear()}</TableCell>
                  <TableCell align="center" sx={{
                    fontWeight: 700
                  }}>{new Date(row.createdAt).getHours() + ':' + new Date(row.createdAt).getMinutes() + ':' + new Date(row.createdAt).getSeconds()}</TableCell>
                  <TableCell align="left">
                    <Typography variant="h6" fontWeight={700}>{row.nama}</Typography>
                    <Typography variant="body2" >{row.opd}</Typography>
                    <Typography variant="body2" >{row.jabatan.toString().toUpperCase()}</Typography>
                  </TableCell>
                  <TableCell align="center">{convertScan(row.scan)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell ref={scrollRef} height={100} align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box marginTop={4}>
        <Card>
          <CardContent>
            <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"}>
              <Grid item>
                <Typography align="center" variant="h4" fontWeight={700}>
                  Total Peserta Hadir
                </Typography>
              </Grid>
              <Grid item marginLeft={2}>
                <Typography align="center" variant="h2" fontWeight={700} color={'#920404'}>
                  {countLog}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Loguser;
