import React, { Suspense } from 'react';
import { Alert, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {
  RouterProvider, createHashRouter,
  createRoutesFromElements, defer,
  Route, useOutlet,
  useLoaderData, Await, Navigate
} from "react-router-dom";
import { AuthProvider, useAuth } from './auth/auth_provider';
// import { AuthProvider, useAuth } from './auth/auth_provider';
import Pendaftaran from './scenes/pendaftaran/daftar';
import Daftarsukses from './scenes/pendaftaran/sukses';
import Cekqr from './scenes/pendaftaran/cekqr';
import Login from './scenes/login';
import Loguser from './scenes/admin/loguser';
import SplashScreen from './scenes/global/spashscreen';
import Logger from './scenes/pendaftaran/logger';
import DaftarUser from './scenes/admin/akun';
import GenerateQR from './scenes/pendaftaran/generateQR';
import TestSocket from './scenes/pendaftaran/testsocket';
import Dashboard from './scenes/admin/dashboard';
import SideMenu from './scenes/global/Menu';
import Pendaftar from './scenes/admin/pendaftar';
import Absens from './scenes/admin/absen';



const App = () => {
  const [theme, colorMode] = useMode()
  // const { user } = useAuth();
  const router =
    createHashRouter(
      createRoutesFromElements(
        <Route path='/' element={<AuthLayout />}
          loader={() => defer({ userPromise: getUserData() })}
        >
          <Route index element={<Pendaftaran />} />
          <Route path="sukses" element={<Daftarsukses />} />
          <Route path="cekqr" element={<Cekqr />} />
          <Route path="testsocket" element={<TestSocket />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<ProtectedLayout />}>
            <Route index element={ <Dashboard /> } />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="pendaftar" element={<Pendaftar />} />
            <Route path="absen" element={<Absens />} />
            <Route path="logger" element={<Logger />} />
            <Route path="loguser" element={<Loguser />} />
            <Route path="daftaruser" element={<DaftarUser />} />
            <Route path="generate" element={<GenerateQR />} />
          </Route>
        </Route>
      )
    );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

const getUserData = () =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
      reject("Error");
    }, 3000)
  );


const ProtectedLayout = () => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <SideMenu />
    </div>
  );
};



// const ProtectedUserLayout = () => {
//   const { user } = useAuth();
//   const outlet = useOutlet();

//   if (user.type === 'user') {
//     return <Alert severity="error">404: Halaman yang anda Cari tidak tersedia</Alert>;
//   }
//   return (<div>{outlet}</div>)
// };

// const ProtectedAdminLayout = () => {
//   const { user } = useAuth();
//   const outlet = useOutlet();

//   if (user.type === 'admin' || user.type === 'user') {
//     return <Alert severity="error">404: Halaman yang anda Cari tidak tersedia</Alert>;
//   }

//   return (<div>{outlet}</div>)
// };

const AuthLayout = () => {
  const oulet = useOutlet();
  const { userPromise } = useLoaderData();

  return (
    <Suspense fallback={<SplashScreen />}>
      <Await
        resolve={userPromise}
        errorElement={<Alert severity="error">404: Halaman yang anda Cari tidak tersedia</Alert>}
        children={
          (user) => (
            <AuthProvider userData={user}>
              {oulet}
            </AuthProvider>
          )
        }
      />
    </Suspense>
  );
}


export default App;
