// import * as React from 'react';
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { headerData } from "../../data/headerCostum";
import Logo from "../../assets/image/logo_refleksi.png";
import SweetAlert2 from "react-sweetalert2";
import { socket } from "../../data/socket";
import { CircularProgress, Modal } from "@mui/material";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}


const Logger = () => {
    const [swalProps, setSwalProps] = useState({});
    const [textValue, setTextValue] = useState('');
    const [socketStatus, setSocketStatus] = useState('diconnected');
    const [loadingProg, setLoadingProg] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoadingProg(true);

        fetch(process.env.REACT_APP_API_URL + "api/pendaftar/logger", {
            method: "post",
            headers: headerData,
            body: JSON.stringify({
                qr: data.get("qr"),
            }),
        })
            .then((res) => {
                setLoadingProg(false);
                return res.json();
            })
            .then((res) => {
                if (res.data) {
                    socket.emit("data_logger", res.data);
                    setSwalProps({
                        show: true,
                        title: "Sukses",
                        icon: "success",
                        text: res.message + ": " + res.data.nama,
                    });
                } else {
                    setSwalProps({
                        show: true,
                        title: "Gagal",
                        icon: "error",
                        text: res.message,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        socket.connect();
        socket.on("connect", () => {
            setSocketStatus('connected');
        });
        return () => { socket.disconnect(); setSocketStatus('disconnected'); }
    }, [setSocketStatus])

    return (
        <Box
            sx={{
                backgroundSize: "cover",
                alignContent: "center",
                alignItems: "center",
            }}
        >
            <Box>
                server: {socketStatus}
            </Box>
            <Modal
                open={loadingProg}
                sx={{
                    height: '100vh',
                    position: "absolute",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: "flex",
                }}>
                <Box>
                    <CircularProgress />
                </Box>
            </Modal>
            <SweetAlert2
                {...swalProps}
                didClose={() => {
                    setSwalProps({});
                    setTextValue('');
                }}
            />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box>
                    <img src={Logo} alt="logo" width={"300px"} />
                </Box>
                <Typography>Registrasi QR Code</Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, width: 500 }}
                >
                    <TextField
                        margin="normal"
                        value={textValue}
                        onChange={(event) => setTextValue(event.target.value)}
                        required
                        autoFocus
                        fullWidth
                        name="qr"
                        label="QR Code"
                        type="text"
                        id="qr"
                        // autoComplete="current-password"
                        size="small"
                    />
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 2 }} />
        </Box>
    );
};

export default Logger;
