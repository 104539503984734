import { Box, Button, Typography } from "@mui/material";
import React, { useRef } from "react";
import QRcode from "react-qr-code";
import exportAsImage from "../../data/htmltoCanvas";
import { useLocation } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Background from '../../assets/image/background_refleksi.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Daftarsukses = () => {
    const getParam = useLocation();
    const user = getParam.state.user;
    const downloadRef = useRef();

    return (
        <Box
            width={'100vw'}
            height={'100vh'}
            sx={{
                // backgroundImage: `url(${Background})`,
                background: `linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.8)), url(${Background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'absolute',
                alignContent: 'center',
                alignItems: 'center',
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        ref={downloadRef}
                        sx={{
                            paddingTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.8)), url(${Background})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            alignContent: 'center',
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={700}
                            color={"#B40000"}
                            fontFamily={'Cinzel Decorative'}
                        >
                            QR Registrasi
                        </Typography>
                        <Typography
                            variant="body1"
                            marginBottom={5}
                            fontWeight={700}
                            color={"#B40000"}
                            fontFamily={'Cinzel Decorative'}
                        >
                            Refleksi Akhir Tahun 2023
                        </Typography>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{
                                    padding: 3,
                                    backgroundColor: "#FFF",
                                    borderRadius: "8px",
                                    border: "2px dashed #000",
                                }}>
                                    <QRcode
                                        size={150}
                                        value={user.qr}
                                        viewBox={`0 0 256 256`}
                                    />
                                </Box>
                                <Typography
                                    variant="h5"
                                    marginTop={2}
                                    fontWeight={700}
                                    align="center"
                                    fontFamily={'Cinzel Decorative'}
                                >
                                    {user.nama}
                                </Typography>
                                <Typography
                                    fontSize={20}
                                    fontWeight={600}
                                    align="center"
                                    fontFamily={'Cinzel'}
                                >
                                    {user.no_hp}
                                </Typography>
                                <Typography
                                    fontSize={15}
                                    fontWeight={600}
                                    marginBottom={2}
                                    align="center"
                                    fontFamily={'Cinzel'}
                                    sx={{
                                        textTransform: "capitalize"
                                    }}
                                >
                                    {user.opd.toString().toLowerCase()}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    marginTop={2}
                                    fontWeight={700}
                                    align={"center"}
                                    fontFamily={'Cinzel Decorative'}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <LocationOnIcon fontSize="small"/> Four Points by Sheraton Makassar
                                </Typography>
                                <Typography
                                    variant="caption"
                                    marginBottom={3}
                                    fontWeight={700}
                                    align="center"
                                    fontFamily={'Cinzel Decorative'}
                                >
                                    27 Desember 2023
                                </Typography>
                                <Typography
                                    variant="caption"
                                    // fontSize={5}
                                    marginBottom={3}
                                    // fontWeight={700}
                                    align="center"
                                    color={'red'}
                                    fontFamily={'Cinzel Decorative'}
                                >
                                    *Harap simpan QR ini untuk akses masuk
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Button
                        variant="contained"
                        size="medium"
                        fullWidth
                        onClick={() => exportAsImage(downloadRef.current, 'qrcode')}
                        sx={{
                            mb: 2,
                            backgroundColor: '#5D0000',
                            textTransform: 'none',
                            ":hover": {
                                backgroundColor: '#B40000'
                            }
                        }}
                    >
                        Download QR
                    </Button>
                    <Copyright sx={{ mt: 2, mb: 2 }} />
                </Container>
            </ThemeProvider>
        </Box>

    );
}

export default Daftarsukses;